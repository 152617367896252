<template>
  <div class="main-content">
    <div class="main-wrapper">

      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

          <mdc-card class="chemistry page-card">
            <mdc-card-primary-action>
              <mdc-card-media class="image-cover" :src="chemistryCheck.headerimage">
                <mdc-card-header>
                  <mdc-card-action-icon style="color:white" @click="$router.go(-1)" icon="arrow_back_ios" />
                  <mdc-card-title>{{chemistryCheck.title}}</mdc-card-title>
                  <mdc-card-subtitle>{{chemistryCheck.subtitle}}</mdc-card-subtitle>
                </mdc-card-header>
              </mdc-card-media>
            </mdc-card-primary-action>

            <mdc-card-actions>
              <mdc-card-action-icons>
                <mdc-card-action-icon @click="save" icon="done" />
                <mdc-card-action-icon @click="print" icon="print" />
              </mdc-card-action-icons>
            </mdc-card-actions>

            <mdc-card-text>
              <section v-for="section in sectionsOrdered" :key="section.key">

                <mdc-title>
                  {{section.title}}
                  <span v-if="editing.includes(section.key)" >
                    <span @click="removeEditSave(section.key, section)" class="mdc-card-action-icon material-icons mdc-card__action mdc-card__action--icon mdc-icon-toggle mdc-ripple-upgraded" style="float:right">done</span>
                    <span @click="removeEditClear(section.key, section)" class="mdc-card-action-icon material-icons mdc-card__action mdc-card__action--icon mdc-icon-toggle mdc-ripple-upgraded" style="float:right">clear</span>
                  </span>
                  <span
                  v-else-if="section.customQuestions || section.newSampleQuestion"
                  @click="addEdit(section.key)"
                  class="mdc-card-action-icon material-icons mdc-card__action mdc-card__action--icon mdc-icon-toggle mdc-ripple-upgraded"
                  style="float:right">question_answer</span>
                </mdc-title>

                <p style="margin: 0">{{section.info}}</p>

                <div class="expandable expanded">
                  <div class="question-wrapper" v-if="section.customQuestions || section.newSampleQuestion">
                    <div style="display:flex;" v-for="(question, index) in section.questions" :key="index">
                      <mdc-body style="flex:1"><B>Q.</B> {{question}}</mdc-body>
                      <mdc-button v-if="editing.includes(section.key)" style="float:right" @click="removeQuestion(section, index)"><mdc-icon icon="clear"/></mdc-button>
                    </div>
                  </div>
                  <div v-if="editing.includes(section.key)">
                    <div style="display:flex;" class="question-input" v-if="section.customQuestions">
                      <mdc-textfield style="flex:1" v-model="section.newCustomQuestion" label="Add your own question" fullwidth />
                      <mdc-button @click="addQuestion(section, section.newCustomQuestion)"><mdc-icon icon="add"/></mdc-button>
                    </div>
                    <div class="question-input" v-if="section.sampleQuestions">
                      <mdc-body style="padding-left:6px;color: #555;">Select from the following sample questions:
                      </mdc-body>

                      <mdc-chip-set v-if="sampleQuestions" filter>
                        <mdc-chip
                          v-for="(qtype, index) in sampleQuestionTypes"
                          :key="index"
                          @selected="toggleFilter(qtype)">
                          {{qtype}}
                        </mdc-chip>
                      </mdc-chip-set>

                      <mdc-list>
                        <mdc-list-item v-for="(question, index) in sampleQuestionsFiltered" :key="index">
                          <mdc-body v-bind:class="{active: section.questions.includes(question.question) }" style="flex:1">{{question.question}}</mdc-body>
                          <mdc-button :disabled="section.questions.includes(question.question)" @click="addQuestion(section, question.question)"><mdc-icon icon="add"/></mdc-button>
                        </mdc-list-item>
                      </mdc-list>
                    </div>
                  </div>
                </div>

                <tiptap-editor @onTextUpdate="onTextUpdate" :title="'Notes'" :content="section.text" :contentKey="section.key"></tiptap-editor>

              </section>
            </mdc-card-text>
          </mdc-card>

        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import uniqBy from 'lodash/uniqBy'
import clone from 'lodash/clone'
const _ = { clone, uniqBy }

export default {
  name: 'chemistryCheck',
  components: {
    TiptapEditor: () => import('../components/Tiptap')
  },
  data () {
    return {
      title: 'Chemistry Check',
      links: [
      ],
      editing: [],
      filters: [],
      connectionId: null,
      sectionBackup: null,
      sectionUpdates: {},
      chemistryCheckActivity: null,
      chemistryCheckDefault: {
        title: 'Chemistry Check',
        subtitle: 'Meeting Planner',
        headerimage: '/static/img/chemistry.png',
        overview: '',
        sectionsOrdered: [
          'greeting',
          'clarification',
          'scenario',
          'wrap'
        ],
        sections: {
          greeting: {
            key: 'greeting',
            title: 'Greetings and Introductions',
            info: 'Getting to know the other person helps break the ice and lets you find common ground. Explain a bit about yourself, your family, and what you like to do',
            questions: [],
            customQuestions: true,
            newCustomQuestion: '',
            text: ''
          },
          clarification: {
            key: 'clarification',
            title: 'Clarification Questions',
            info: 'This is the chance to ask any specific question you had about the information you\'ve received so far. Clarify questions you have on experience and skills.',
            questions: [],
            customQuestions: true,
            newCustomQuestion: '',
            text: ''
          },
          scenario: {
            key: 'scenario',
            title: 'Scenario Questions',
            info: 'Scenario-based question provides better context on how someone reacts and performs in a specific situation. Pick out two or three questions you think are interesting and relevant so you can ask during your session.',
            questions: [],
            sampleQuestions: true,
            newSampleQuestion: '',
            customQuestions: true,
            newCustomQuestion: '',
            text: ''
          },
          wrap: {
            key: 'wrap',
            title: 'Wrap up and final thoughts',
            info: 'As you approach the end of the meeting, you should wrap up and commit to provide feedback to other partner within the next 48 hours.',
            text: ''
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      sampleQuestions: state => state.sampleQuestions.questions,
      user: state => state.user
    }),
    sampleQuestionTypes: function () {
      if (this.sampleQuestions) {
        return _.uniqBy(this.sampleQuestions, 'type').map(quest => quest.type)
      }
      return []
    },
    sampleQuestionsFiltered: function () {
      if (this.filters.length > 0) {
        return this.sampleQuestions.filter(q => this.filters.includes(q.type))
      }
      return this.sampleQuestions
    },
    chemistryCheck: function () {
      if (this.chemistryCheckActivity) {
        return this.chemistryCheckActivity
      } else {
        return this.chemistryCheckDefault
      }
    },
    sectionsOrdered: function () {
      if (this.chemistryCheck) {
        if (this.chemistryCheck.sectionsOrdered) {
          return this.chemistryCheck.sectionsOrdered.map((key) => {
            return this.chemistryCheck.sections[key]
          })
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions(
      [
        'getChemistryCheckForConnection',
        'setChemistryCheckForConnection',
        'setSnackMessage',
        'setPageLoad'
      ]
    ),
    print: function () {
      window.print()
    },
    save: function () {
      for (const updateKey in this.sectionUpdates) {
        this.chemistryCheck.sections[updateKey].text = this.sectionUpdates[updateKey]
      }
      this.setChemistryCheckForConnection({ connectionId: this.connectionId, userId: this.user.uid, chemistryCheck: this.chemistryCheck }).then(() => {
        this.setSnackMessage('Chemistry Check updated')
      })
    },
    toggleFilter: function (qtype) {
      if (this.filters.includes(qtype)) {
        this.filters = this.filters.filter(e => e !== qtype)
      } else {
        this.filters.push(qtype)
      }
    },
    onTextUpdate: function (text, contentKey) {
      this.sectionUpdates[contentKey] = text
    },
    addQuestion: function (section, question) {
      section.questions.push(question)
      section.newCustomQuestion = ''
      section.newSampleQuestion = ''
    },
    removeQuestion: function (section, index) {
      section.questions.splice(index, 1)
    },
    addEdit: function (sectionKey) {
      this.sectionBackup = _.clone(this.chemistryCheck.sections[sectionKey])
      this.editing.push(sectionKey)
    },
    removeEdit: function (sectionKey) {
      this.editing = this.editing.filter((str) => { return str !== sectionKey })
    },
    removeEditSave: function (sectionKey, section) {
      this.removeEdit(sectionKey)
      this.sectionBackup = null
    },
    removeEditClear: function (sectionKey, section) {
      this.removeEdit(sectionKey)
      if (this.chemistryCheckActivity) {
        this.chemistryCheckActivity.sections[sectionKey] = this.sectionBackup
      } else {
        this.chemistryCheck.sections[sectionKey] = this.sectionBackup
      }
      this.sectionBackup = null
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
  },
  mounted () {
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.connectionId = (this.$route) ? this.$route.params.id : 'ijk789'
    if (this.user.uid) {
      this.getChemistryCheckForConnection({ connectionId: this.connectionId, userId: this.user.uid }).then(res => {
        this.chemistryCheckActivity = res
      })
    }
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    user: function (newU, oldU) {
      if (newU.uid) {
        this.getChemistryCheckForConnection({ connectionId: this.connectionId, userId: this.user.uid }).then(res => {
          this.chemistryCheckActivity = res
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.chemistry {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
}
.chemistry .question-wrapper {
  padding:16px;
}
.chemistry .section-notes {
  margin-top:16px;
}
.chemistry .expandable {
  -webkit-transition: max-height 1s; /* For Safari 3.1 to 6.0 */
  transition: max-height 1s;
  max-height:0;
  overflow: hidden;
}
.chemistry .expandable.expanded {
  max-height:1000px;
  overflow-x:auto;
}
.chemistry .mdc-text-field--fullwidth {
    width: calc(100% - 10px);
}
.chemistry select option {
  /* wrap text in compatible browsers */
  -moz-white-space:pre-wrap;
  -o-white-space:pre-wrap;
  white-space:pre-wrap;

  /* hide text that can't wrap with an ellipsis */
  overflow: hidden;
  text-overflow:ellipsis;

  /* add border after every option */
  border-bottom: 1px solid #DDD;
}
.section-notes .mdc-textfield {
  border-color: #ccc;
}
.section-notes textarea::placeholder {
  color: #333 !important;
}
.section-notes .mdc-text-field__input {
  padding:6px;
}
.question-input .mdc-button {
  margin-top: 6px;
  height: 46px;
}
.question-input .mdc-list {
  height: 200px;
  overflow: hidden scroll;
}
.question-input .mdc-list .mdc-list-item {
  display: flex;
  flex-flow: row;
  height: auto;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}
.question-input .mdc-list .mdc-list-item p.active{
  font-weight: 500;
}
.chemistry .section-notes {
  white-space: pre;
}
</style>
